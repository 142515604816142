@import "theme/font-awesome";
@import "theme/variables";
@import "node_modules/bulma/bulma";
@import "theme/overrides";
@import "mixins";


section.has-bg-header {
    background-image: url('../images/header-bg.jpg');
    h1.title, h2.subtitle {
        color: $white-ter;
    }
}

section.has-bg-trips {
    background-image: url('../images/header-trips.jpg');
}

section.has-bg-trips, section.has-bg-header {
    @include background-cover;
    background-color: #333;
}