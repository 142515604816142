////////////////////////////////////////////////
// No theme
////////////////////////////////////////////////

$primary: #c1d72e !default;
$yellow: #f68428 !default;
$cyan: #0096e7 !default;
$column-gap: 1.5rem !default;
$title-color: #555 !default;
$label-color: #555 !default;
$card-shadow: 0 5px 15px rgba(#000, 0.5), 0 0 0 1px rgba(#000, 0.1) !default;