
@import "layout";


body {
  font-size: 1.1rem;
}

.admin-bar .navbar.is-fixed-top {
    top: 32px;
}

.landing .title {
    font-size: 3.5rem;
}

.hero-body p:not([class]) {
  @extend .subtitle;
}

.footer {
  padding: 3rem 1.5rem;
}

.section .container .title {
    padding-bottom: 3rem;
}

.has-background-info-gradient {
    background-image: linear-gradient($info, $link);
}

#contact.column {
    margin-top: -6rem;
    .card {
        border-radius: 0.8rem;
    }
}

footer.footer {
    background: url('../images/icon.png') no-repeat right -50px bottom -10px;
    background-size: 250px 250px;
}